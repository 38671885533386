<app-st-card-view title="User Info" subtitle="Consumer API" [loading]="loading">
  <ng-container *ngIf="consumer">
    <app-st-card-view-row class="col-12" title="Name" >
      {{consumer.givenName + ' ' + consumer.familyName}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Email"  >
      {{consumer?.email}}
      <i *ngIf="consumer?.emailVerified" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Verified"></i>
      <i *ngIf="consumer && !consumer?.emailVerified" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Not Verified"></i>    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Phone" *ngIf="consumer.hasOwnProperty('phoneVerified')">
      {{consumer?.phoneNumber | phone}}
      <i *ngIf="consumer?.phoneVerified" style="color:green" class="fa fa-check-circle-o fa-lg"  pTooltip="Verified"></i>
      <i *ngIf="consumer && !consumer?.phoneVerified" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Not Verified"></i>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Locked" *ngIf="consumer?.locked != null">
      <p-inputSwitch (onChange)="handleLockChange($event)" [(ngModel)]="consumer.locked" [disabled]="lockDisable"></p-inputSwitch>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Profile" *ngIf="consumer?.thumbnailUrl">
      <img [src]="consumer?.thumbnailUrl" width="50px" height="50px" />
    </app-st-card-view-row>

  </ng-container>

<!--  <div class="text-center col-12" *ngIf="consumer?.id">-->
<!--    <button pButton  class="btn btn-danger" type="button" class="my-1" label="Delete Consumer" (click)="deleteConsumer()"></button>-->
<!--  </div>-->

  <div class="text-center col-12" *ngIf="!(consumer)">
    Could not load consumer
  </div>
</app-st-card-view>

<p-dialog  header="Lock user" [(visible)]="showLockModal" [modal]="true" [style]="{width: '400px'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" class="container" [closable]="false">
  <div class="form-group col-12">
    <label class="text-uppercase" for="comments" >Comments</label>
    <textarea class="form-control form-control-lg" id="comments" #comments></textarea>
  </div>

  <p-footer>
    <ng-container *ngIf="lockIsLoading">
      <p-progressSpinner [style]="{'width':'40px', 'height':'35px'}"></p-progressSpinner>
    </ng-container>
    <ng-container *ngIf="!lockIsLoading">
      <button type="button" pButton icon="pi pi-times" (click)="cancelLock()" label="Cancel" class="ui-button-secondary"></button>
      <button type="button" pButton icon="pi pi-check" (click)="handleLockRequest(true, comments.value)" label="Confirm"></button>
    </ng-container>
  </p-footer>
</p-dialog>
