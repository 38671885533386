<div class="container content p-2" *ngIf="!consumer?.id && !userIsLoading">
  <div class="row">
    <div class="col-12 text-center">
      <i class="fa fa-warning"></i><br/>
      Unable to load consumer.
    </div>
  </div>
</div>
<div class="container content pt-2" *ngIf="consumer?.id" [ngClass]="{'border-danger bg-light': 'userIsClosed' == 'true'}">
  <div class="row">
    <div class="col-8 col-md-10" >
      <ng-container  *ngIf="consumer?.givenName || consumer?.familyName">
        <h3>
          {{consumer?.givenName}} {{consumer?.familyName}}
          <small class="ml-1"><i class="fa fa-pencil text-muted" pTooltip="Edit Name"  (click)="showUpdateName = true"></i></small>
        </h3>
        <span class="text-muted "><app-copy-text [display]="consumer?.id"></app-copy-text> </span>
      </ng-container>
      <ng-container  *ngIf="!consumer?.givenName && !consumer?.familyName">
        <h3>{{consumer?.id}}  </h3>
        <button class="btn btn-outline-secondary btn-sm" (click)="showUpdateName = true">Add name</button>
      </ng-container>
    </div>
    <div class="col-4 col-md-2 text-right">
<!--      <button class="btn btn-danger" (click)="confirmDelete()">Delete</button>-->
      <div *ngIf="!isExtRoles" class="dropdown pt-1">
        <a class="btn btn-outline-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Actions
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
<!--          <a class="dropdown-item" href="#/application?user={{consumer?.id}}">Loan Dry Run</a>-->
          <button class="dropdown-item" (click)="navigateTo('offers/rewardtransaction?user='+consumer?.id)">Give Reward Campaign</button>
          <button class="dropdown-item" (click)="navigateTo('offers/rewardcampaign?affiliate='+consumer?.id)">Create Affiliate Reward Campaign</button>
        </div>
      </div>
    </div>
    <div class="col-12 py-0 my-0">
      <hr>
    </div>
  </div>

  <div class="row"  *ngIf="userIsLoading">
    <div class="col">&nbsp;</div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col">&nbsp;</div>
  </div>

  <p-tabView class="tabview"*ngIf="consumer  && !userIsLoading">
    <p-tabPanel header="Identity">
      <div class="row">
        <app-consumer-card [consumer]="consumer" class="col-12 col-md-6 col-lg-4" ></app-consumer-card>
        <app-kyc-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-kyc-card>
        <app-offers-user-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-offers-user-card>
        <app-addresses-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-6" ></app-addresses-card>
        <app-wallet-mobiles-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-6" ></app-wallet-mobiles-card>
        <app-id-score-card [consumerId]="userUuid" class="col-12 col-md-12 col-lg-8" ></app-id-score-card>
        <app-wallet-properties-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-properties-card>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Payments">
      <div class="row">
        <app-wallet-ovcard-card (notifyOvCardChange)="ovCardChange($event)" [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-ovcard-card>
        <app-funds-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-funds-card>
        <app-wallet-topup-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-topup-card>
        <app-wallet-cards-card [ovCardLastChange]="ovCardLastChange" [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-cards-card>
        <app-wallet-banks-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-banks-card>
        <app-wallet-verified-banks-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-verified-banks-card>
        <app-wallet-valets-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-valets-card>
        <app-wallet-tokens-card [ovCardLastChange]="ovCardLastChange" [consumerId]="userUuid" class="col-12 col-md-6 col-lg-6" ></app-wallet-tokens-card>
      </div>
    </p-tabPanel>

    <p-tabPanel *ngIf="!isExtRoles" header="Wallet Events">
      <div class="row">
        <div class="col-12">
          <app-valet-events-table [consumerId]="consumer?.id"></app-valet-events-table>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Activity">
      <div class="row">
        <div class="col-12">
          <app-activities-table [consumerId]="consumer?.id"></app-activities-table>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Other">
      <div class="row">
        <app-st-card-view class="col-12 mb-2"  title="{{selectedTab}}" [fillBody]="true">
          <div class="st-card-subtitle">
            <p-dropdown class="p-fluid" styleClass="form-control p-0" [options]="tabs" [(ngModel)]="selectedTab"></p-dropdown>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'KYC Attempts'">
            <app-sherlock-list [userUuid]="consumer?.id" ></app-sherlock-list>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'Topups'">
            <app-topups-table [consumerId]="consumer?.id"></app-topups-table>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'Ficentive Transactions'">
            <ficentive-transaction-table [consumerId]="consumer?.id"></ficentive-transaction-table>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'SmartPay Transactions'">
            <app-transactions-table [consumerId]="consumer?.id"></app-transactions-table>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'ClosedLoop Transactions'">
            <app-closed-loop-transactions-table [consumerId]="consumer?.id"></app-closed-loop-transactions-table>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'Tokenized Transactions'">
            <app-tokenized-transactions-table [consumerId]="consumer?.id" ></app-tokenized-transactions-table>
          </div>
          <div class="col-12" *ngIf="selectedTab == 'Gateway Transactions'">
            <app-transactions-cof-table [consumerId]="consumer?.id" ></app-transactions-cof-table>
          </div>
<!--          <div class="col-12" *ngIf="selectedTab == 'Offers'">-->
<!--            <app-offer-list [user]="consumer?.id"></app-offer-list>-->
<!--          </div>-->
<!--          <div class="col-12" *ngIf="selectedTab == 'Loans'">-->
<!--            <app-loan-list [user]="consumer?.id"></app-loan-list>-->
<!--          </div>-->
          <div class="col-12" *ngIf="selectedTab == 'Wallet Failure Events'">
            <app-wallet-failure-events-table [consumerId]="consumer?.id"></app-wallet-failure-events-table>
          </div>
        </app-st-card-view>
      </div>
    </p-tabPanel>
  </p-tabView>

<!--  <div class="row"  *ngIf="consumer  && !userIsLoading">-->
<!--    <app-consumer-card [consumer]="consumer" class="col-12 col-md-6 col-lg-4" ></app-consumer-card>-->
<!--    <app-kyc-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-kyc-card>-->
<!--    <app-wallet-ovcard-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-ovcard-card>-->
<!--    <app-offers-user-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-offers-user-card>-->
<!--    <app-addresses-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-addresses-card>-->
<!--    <app-funds-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-funds-card>-->
<!--    <app-wallet-mobiles-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-mobiles-card>-->
<!--    <app-wallet-valets-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-valets-card>-->
<!--    <app-wallet-tokens-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-tokens-card>-->
<!--    <app-wallet-cards-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-cards-card>-->
<!--    <app-wallet-banks-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-banks-card>-->
<!--    <app-wallet-topup-card [consumerId]="userUuid" class="col-12 col-md-6 col-lg-4" ></app-wallet-topup-card>-->
<!--    <app-id-score-card [consumerId]="userUuid" class="col-12 col-md-12 col-lg-8" ></app-id-score-card>-->

<!--    <app-st-card-view class="col-12 mb-2"  title="{{selectedTab}}" [fillBody]="true">-->
<!--      <div class="st-card-subtitle">-->
<!--        <p-dropdown [options]="tabs" [(ngModel)]="selectedTab"></p-dropdown>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'KYC Attempts'">-->
<!--        <app-sherlock-list [userUuid]="consumer?.id" ></app-sherlock-list>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'SmartPay Transactions'">-->
<!--        <app-transactions-table [consumerId]="consumer?.id" ></app-transactions-table>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Tokenized Transactions'">-->
<!--        <app-tokenized-transactions-table [consumerId]="consumer?.id" ></app-tokenized-transactions-table>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Gateway Transactions'">-->
<!--        <app-transactions-cof-table [consumerId]="consumer?.id" ></app-transactions-cof-table>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Offers'">-->
<!--        <app-offer-list [user]="consumer?.id"></app-offer-list>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Loans'">-->
<!--        <app-loan-list [user]="consumer?.id"></app-loan-list>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Activity'">-->
<!--        <app-activities-table [consumerId]="consumer?.id"></app-activities-table>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Wallet Events'">-->
<!--        <app-valet-events-table [consumerId]="consumer?.id"></app-valet-events-table>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Wallet Failure Events'">-->
<!--        <app-wallet-failure-events-table [consumerId]="consumer?.id"></app-wallet-failure-events-table>-->
<!--      </div>-->
<!--      <div class="col-12" *ngIf="selectedTab == 'Topups'">-->
<!--        <app-topups-table [consumerId]="consumer?.id"></app-topups-table>-->
<!--      </div>-->
<!--    </app-st-card-view>-->
<!--  </div>-->
</div>

<p-dialog [(visible)]="showUpdateName" modal="modal" [closable]="false" [dismissableMask]="true">
  <p-header>
    Update Name
  </p-header>
  <input type="text" class="form-control" pInputText placeholder="First Name" #firstNameInput><br>
  <input type="text" class="form-control" pInputText placeholder="Last Name" #lastNameInput >
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="updateName(firstNameInput.value, lastNameInput.value)" label="Confirm"></button>
    <button type="button" pButton (click)="showUpdateName=false" label="Cancel"></button>
  </p-footer>
</p-dialog>

<!--<p-dialog [(visible)]="false" modal="modal" [closable]="false" [dismissableMask]="true">-->
<!--  <p-header>-->
<!--    Add Card Source-->
<!--  </p-header>-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="PAN" #panInput>-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="CVC" #cvcInput ><br>-->
<!--  Expiration-->
<!--  <input type="text" class="form-control mb-1" pInputText placeholder="MM" #expirationMonthInput >-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="YYYY" #expirationYearInput >-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="Zip" #zipInput ><br>-->
<!--  Name-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="First Name" #firstNameInput >-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="Last Name" #lastNameInput >-->
<!--  <p-footer>-->
<!--&lt;!&ndash;    <button type="button" pButton icon="pi pi-check" (click)="createCardSource(panInput.value, cvcInput.value, expirationMonthInput.value, expirationYearInput.value, zipInput.value, firstNameInput.value, lastNameInput.value)" label="Confirm"></button>&ndash;&gt;-->
<!--&lt;!&ndash;    <button type="button" pButton (click)="showAddCardSource=false" label="Cancel"></button>&ndash;&gt;-->
<!--  </p-footer>-->
<!--</p-dialog>-->

<!--<p-dialog [(visible)]="false" modal="modal" [closable]="false" [dismissableMask]="true">-->
<!--  <p-header>-->
<!--    Add Bank Source-->
<!--  </p-header>-->
<!--  <div  class="form-control my-1" >-->
<!--  <p-radioButton name="accountTypeInput" value="CHECKING" label="Checking" #accountTypeInput></p-radioButton><br>-->
<!--  <p-radioButton name="accountTypeInput" value="SAVINGS" label="Savings" #accountTypeInput></p-radioButton>-->
<!--  </div>-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="Routing Number" #routingNumberInput>-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="Account Number" #accountNumberInput>-->
<!--  <input type="text" class="form-control my-1" pInputText placeholder="Name" #accountNameInput >-->
<!--  <p-footer>-->
<!--&lt;!&ndash;    <button type="button" pButton icon="pi pi-check" (click)="createBankSource(routingNumberInput.value, accountNumberInput.value, accountTypeInput.value, accountNameInput.value)" label="Confirm"></button>&ndash;&gt;-->
<!--&lt;!&ndash;    <button type="button" pButton (click)="showAddBankSource=false" label="Cancel"></button>&ndash;&gt;-->
<!--  </p-footer>-->
<!--</p-dialog>-->


