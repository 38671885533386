// Build information, automatically generated by `ng-info`
const build = {
    version: "2.25.0",
    timestamp: "Mon Jan 20 2025 18:05:37 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "develop",
        hash: "860e91",
        fullHash: "860e91b6e2f0ff7af3a27e13f11d63979a03e785"
    }
};

export default build;