import {EventEmitter, Injectable, OnInit} from '@angular/core';
import {from, of, Observable, BehaviorSubject, combineLatest, throwError, forkJoin} from 'rxjs';
import {map, tap, catchError, concatMap, shareReplay, delay, switchMap, filter, take} from 'rxjs/operators';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable()
export class AuthService {
  private urlFromAuth: string;
  private jwtHelperService = new JwtHelperService();

  loggedInEmitter = new EventEmitter<boolean>();
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth.domain,
      client_id: environment.auth.clientID,
      redirect_uri: `${window.location.origin}`,
      audience: environment.auth.audience
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback(this.urlFromAuth)))
  );

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  // Create a local property for login status
  loggedIn: boolean = null;

  private userRoleSubject$ = new BehaviorSubject<any>(null);
  userRole$: Observable<string> = this.userRoleSubject$.asObservable();

  constructor(private router: Router) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    // super();
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  getRole$(): Observable<any> {
    return this.getTokenSilently$().pipe(
      concatMap(token => {
        const decodedToken = this.jwtHelperService.decodeToken(token);
        return of(decodedToken['https://claims.ovloop.com/roles']);
      }),
      tap(roles => this.userRoleSubject$.next(roles))
    )
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return forkJoin([this.getUser$(), this.getRole$()]);
        }
        // If not authenticated, return stream that emits 'false'
        return of([loggedIn, []]);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists

    const redirect_url = `${window.location.origin}`;
    console.log(`redirectPath ${redirectPath}`);
    console.log( `window location origin ${window.location.origin}`);
    console.log( `redirect_url ${redirect_url}`);

    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: redirect_url,
        appState: { target: redirectPath }
      });
    });
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    console.log(`Auth Callback - params: ${params}`);
    this.urlFromAuth = window.location.href;

    if (params.includes('code=') && params.includes('state=')) {
      console.log('Auth Callback Processing');
      let targetRoute: string; // Path to redirect to after login processsed

      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          console.log(`targetRoute ${targetRoute}`);
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.getRole$(),
            this.isAuthenticated$
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        console.log(`targetRoute about to happen ${targetRoute}`);
        this.router.navigateByUrl(targetRoute);
      });
    }
  }

  hasRole(rolesRequired: string[]): Observable<boolean> {
    // Checks whether the user has the passed role within their token
    return this.userRole$.pipe(
      map(role => {
        const hasRole = rolesRequired.findIndex(requiredRole => role.indexOf(requiredRole) != -1);
        return hasRole != -1;
      })
    );
  }

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.auth.clientID,
        returnTo: `${window.location.origin}/`
      });
    });
  }
}
