import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Consumer} from '../../../domain/consumers/consumer';
import {ConsumersApiService} from '../../../services/consumers-api.service';
import {WalletService} from '../../../services/wallet.service';
import {AuthRoles} from "../../../domain/auth/auth-roles";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-consumer-card',
  templateUrl: './consumer-card.component.html',
  styleUrls: ['./consumer-card.component.css']
})
export class ConsumerCardComponent implements OnInit, OnChanges {

  @Input()
  consumer: Consumer;

  @Input()
  consumerId: string;

  loading = false;

  showLockModal = false;
  lockIsLoading = false;
  lockDisable= true;

  constructor(private consumersApi: ConsumersApiService,
              private walletService: WalletService,
              private authService: AuthService) {

  }

  ngOnInit(): void {
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(results => {
      this.lockDisable = !results;
    });
    if (!this.consumer && this.consumerId) {
      this.getConsumer();
    } else if (this.consumer) {
      this.getLockStatus(this.consumer.id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.consumerId?.currentValue && changes.consumerId.currentValue !== changes.consumerId.previousValue) {
      this.getConsumer();
    }
  }

  getConsumer() {
    this.loading = true;
    this.consumersApi.getConsumer(this.consumerId).subscribe(result => {
      this.consumer = result;
      this.loading = false;
      this.getLockStatus(this.consumerId);
    });
  }

  getLockStatus(consumerId) {
    this.walletService.getLockStatus(consumerId).subscribe(
      status => {
        this.consumer.locked = !!status.lockedDate;
      }
    )
  }

  deleteConsumer() {
    console.warn('Delete consumer not implemented');
  }

  cancelLock() {
    this.showLockModal = false;
    this.consumer.locked = false;
  }

  handleLockChange(e) {
    let isChecked = e.checked;
    console.log(isChecked ? 'true' : 'false');
    if (isChecked) {
      this.showLockModal = true;
      this.lockIsLoading = false;
      return;
    } else {
      this.handleLockRequest(isChecked)
    }
  }

  handleLockRequest(isChecked, lockComments = '') {
    const consumerId = this.consumer?.id ? this.consumer.id : this.consumerId;
    this.lockIsLoading = true;
    if (isChecked) {
      this.walletService.lockAccount(consumerId, lockComments).subscribe(success => {
        console.log(`Locked user ${this.consumerId}`);
        this.showLockModal = false;
        this.lockIsLoading = false;
      }, error => {
        console.log(`Could not lock user ${this.consumerId}`);
        this.consumer.locked = false;
        this.showLockModal = false;
        this.lockIsLoading = false;
      });
    } else {
      this.walletService.unlockAccount(consumerId).subscribe(success => {
        console.log(`Removed lock ${this.consumerId}`);
      }, error => {
        console.log(`Could not remove lock for user ${this.consumerId}`);
        this.consumer.locked = true;
      });
    }
  }

}
