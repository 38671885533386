<app-st-card-view title="ID Score" [loading]="loading" [error]="failedApi">

  <div class="st-card-subtitle">
    <ng-container  *ngIf="idScore">
      <span class="font-weight-bold mr-1">{{ idScore.score_report.percentile * 100.0 | number : '1.0-0'}}</span>
      <i class="fa fa-thumbs-down text-danger" *ngIf="idScore.score_report.percentile <= 0.3"></i>
      <i class="fa fa-meh-o text-warning" *ngIf="idScore.score_report.percentile > 0.3 && idScore.score_report.percentile < 0.7"></i>
      <i class="fa fa-thumbs-up text-success" *ngIf="idScore.score_report.percentile >= 0.7"></i>
    </ng-container>
  </div>

  <ng-container *ngIf="idReport">
    <div class="col-12">
      <table class="w-100">
        <tr>
          <th>Weight</th>
          <th>Rule</th>
          <th>Value</th>
        </tr>
        <tr *ngFor="let reason of idScore?.score_report?.reasons">
          <td>{{ reason.weight }}</td>
          <td>{{ reason.rule }}</td>
          <td>{{ reason.value }}</td>
        </tr>
        <tr>
          <td><b>{{ idScore.score_report.raw_score }}</b></td>
          <td><b>RAW SCORE</b></td>
          <td></td>
        </tr>
      </table>
    </div>
  </ng-container>
  <div class="col-12">
    <button [disabled]="identityReportDisable" class="btn btn-link p-0 m-0 pull-right"
            (click)="showIdentityReportTable = true">View Identity Report history</button>
  </div>
  <app-st-card-view-row class="col-12">
  </app-st-card-view-row>
</app-st-card-view>

<p-dialog modal="modal" header="Identity Report History" [draggable]="false" [resizable]="false" [(visible)]="showIdentityReportTable" [dismissableMask]="true"  [style]="{width: '50%'}">
  <div class="row">
    <p-table [value]="identityReportHistory" [columns]="identityReportHistoryCols" [rows]="25" [paginator]="true" [pageLinks]="8"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="identityReportHistoryLoading" [totalRecords]="identityReportHistoryTotalRecords"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #identityReportHistoryDt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of identityReportHistoryCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-report>
        <tr>
          <td *ngFor="let col of identityReportHistoryCols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'created'">
              {{ report?.created | date:'medium':'UTC -6' }}
            </ng-container>
            <ng-container *ngSwitchCase="'score'">
              {{ (report?.identityReportResponse?.score_report?.percentile * 100) - (report?.identityReportResponse?.score_report?.percentile * 100) % 1}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{report[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="identityReportHistoryCols.length" class="text-center w-100">
            No reports found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
